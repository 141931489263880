
.graph{
    line {
        opacity: 1 !important;
    }
    
    g {
        opacity: 1 !important;
    
    }
}