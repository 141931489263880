$out-of-range-color: lighten(#333, 40%) !default;
$out-of-range-bg-color: lighten(#333, 70%) !default;
$calendar-border: rgb(243, 243, 243) !default;
$cell-border: rgb(222, 222, 223) !default;
// $border-color: rgb(0, 226, 38) fault;
$segment-width: percentage(1 / 7) !default;
$time-selection-color: white !default;
$time-selection-bg-color: rgba(0, 0, 0, 0.5) !default;
$date-selection-bg-color: rgba(0, 0, 0, 0.1) !default;
$event-bg: #1b5d96 !default;
// $event-border: darken(#1b5d96, 20%) !default;
$event-border: none !default;
// $event-outline: #05192b !default;
// $event-color: #fff !default;
$event-border-radius: 4px !default;
$event-padding: 2px 5px !default;
$event-zindex: 4 !default;
$btn-color: #2e383f !default;
$btn-bg: #fff !default;
$btn-border: #ccc !default;
$current-time-color: #74ad31 !default;
$rbc-css-prefix: rbc-i !default;
$today-highlight-bg: #f5f5f5 !default;

.rbc-time-slot {
    padding-top: 5px;
}

.rbc-allday-cell {
    display: none;
}

.rbc-label {
    color: rgb(109, 109, 109);
}

.rbc-time-header-cell > .rbc-header {
    font-size: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.rbc-time-view > .rbc-time-content {
    border-top: 0;
}

.rbc-header + .rbc-today {
    color: white;
    border-radius: 4px;
    background-color: #1565c0;
}

.rbc-time-view {
    border-radius: 4px;
}

.rbc-agenda-table > tr {
    background-color: white !important;
}

.rbc-time-gutter,
.rbc-time-header-gutter {
    position: sticky;
    left: 0;
    background-color: white;
    border-right: 1px solid #ddd;
    z-index: 10;
    margin-right: -1px;
}

.rbc-time-header-cell > .rbc-header {
    min-width: 140px;
    overflow: hidden;
}

.rbc-time-header {
    overflow: hidden;
}

.rbc-time-header-content {
    min-width: auto;
    flex: 1 0 0;
    flex-basis: 0px;
}

.rbc-time-header-cell-single-day {
    display: none;
}

.rbc-day-slot {
    min-width: 140px;
}

.rbc-event {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
    transition: box-shadow 0.2s ease-in-out !important;
    &:hover {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.2) !important;
    }
    &:active {
        box-shadow: none !important;
    }
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    font-size: 11px;
}

.rbc-header,
.rbc-day-bg {
    width: 140px;
    flex: 1 1 0;
    flex-basis: 0 px;
}

.rbc-agenda-table {
    thead {
        display: none;
    }
    .rbc-agenda-time-cell,
    .rbc-agenda-event-cell,
    .rbc-agenda-date-cell {
        background-color: white;
    }
    tbody {
        tr {
            border: none;
        }
    }
}

.loading .rbc-time-view,
.loading .rbc-month-view {
    opacity: 0.5;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

@import "~react-big-calendar/lib/sass/styles";
